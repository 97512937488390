.grid {
  display: grid;
  place-items: center;
}

.main-container {
  @media screen and (max-width: 1536px) {
    width: 100%;
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexEven {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}


.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexTop {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.flexTopEven {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexAround {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.flexStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flexBase {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.flexGap05 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.flexGap1 {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.flexGap3 {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.flex-left {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 0.5rem;
}

.wrap {
  flex-wrap: wrap;
}

.wrap-column {
  flex-wrap: wrap;
  flex-direction: column;
  gap: 1rem;
}

.fixedBottom {
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  padding: 0.2rem;
  /*box-shadow: 3px 4px 6px 5px rgb(208, 208, 208)!important;*/
  width: 100%;
}

.fixedTop {
  position: sticky;
  top: 0;
  z-index: 100;
  /*min-height: fit-content !important;*/

  background-color:rgb(15,20,33);
  min-height: 85px !important;

}

.container {
  padding: 2rem;
}

.h1 {
  font-size: 50pt !important;
  font-weight: bold !important;
}

.h2 {
  font-weight: bolder !important;
  font-family: "Barlow" !important;
  font-size: 24pt !important;
}

.h4 {
  font-weight: bolder !important;
  font-family: "Barlow" !important;
  font-size: 18pt !important;
}

.h5 {
  font-weight: bolder !important;
  font-family: "Barlow" !important;
  font-size: 13pt !important;
}

.h6 {
  font-size: 10pt !important;
}

.body {
  font-size: 10pt !important;
}

.h6-bold {
  font-weight: bolder !important;
  font-size: 10pt;
}

.h7-barlow {
  font-weight: bolder !important;
  font-family: "Barlow" !important;
  font-size: 10pt !important;
}

.body2 {
  font-size: 5pt;
}

.width100 {
  width: 100% !important;
}

.marginTop1 {
  margin-top: 1rem;
}

.marginTop05 {
  margin-top: 0.5rem;
}

.paddingRight3 {
  padding-right: 3.5rem;
}

.bold {
  font-weight: bold !important;
}

.margin-bottom2 {
  margin-bottom: 2rem !important;
}

.no-mar-pad {
  margin: 0 !important;
  padding: 0 !important;
}

.noBorderBot {
  border-bottom: 0 !important;
}

.marginLeft {
  margin-left: 0.5rem !important;
}

.center {
  text-align: center;
}

.flex100 {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
}

.containerNoMargin {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0 !important;
}

.max30 {
  max-width: 30%;
}

.width1_2 {
  width: 1.2rem !important;
}

.MuiSvgIcon-root.Mui-completed {
  color: #00bfa5 !important;
}

.MuiSvgIcon-root.Mui-active {
  fill: #ffffff !important;
  border: 1px solid #00bfa5 !important;
  border-radius: 50%;
}

.MuiSvgIcon-root.Mui-active > .MuiStepIcon-text {
  fill: #00bfa5 !important;
}

.MuiSvgIcon-root.MuiStepIcon-root {
  color: rgb(220, 220, 220);
}

.Mui-active.MuiStepConnector-root > .MuiStepConnector-line {
  border-color: #00bfa5 !important;
}

.MuiStepConnector-line {
  transition: border-color 1s linear;
}

.Mui-completed.MuiStepConnector-root > .MuiStepConnector-line {
  border-color: #00bfa5 !important;
}

.amplify-button[data-variation="primary"] {
  background: rgb(8, 63, 101) !important;
}

.amplify-button[data-variation="primary"]:hover {
  background: rgb(0, 227, 207) !important;
}

[data-amplify-authenticator][data-variation="modal"] {
  background-color: white !important;
}

.amplify-button[data-variation="link"] {
  color: rgb(8, 63, 101) !important;
}

[data-amplify-authenticator] [data-amplify-router] {
  border-color: transparent !important;
  border-radius: 25px !important;
  box-shadow: 3px 4px 6px 5px rgb(208, 208, 208) !important;
}

.amplify-heading {
  color: rgb(8, 63, 101) !important;
  font-family: "Barlow" !important;
  font-size: 20pt !important;
}

.signin-heading {
  color: rgb(0, 227, 207) !important;
  font-weight: bolder !important;
  font-family: "Barlow" !important;
  font-size: 20pt !important;
}

.logo {
  /* background-color: white;*/
  border-radius: 50%;
  margin-top: 0.5rem;
  /*border: 0.4px solid white;*/
}

.amplify-text {
  color: rgb(8, 63, 101) !important;
  font-family: "Barlow" !important;
  font-size: 12pt !important;
}

.signin-footer {
  color: rgb(0, 227, 207) !important;
  font-weight: bolder !important;
  font-family: "Barlow" !important;
  font-size: 12pt !important;
}

.appBar {
  background-color: rgb(15, 20, 33) !important;
  color: white !important;
  min-height: 85px !important;
}

.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.MuiPaper-root-MuiDrawer-paper {
  display: flex !important;
  flex-direction: column !important;
  align-content: flex-start !important;
  justify-content: space-between !important;
}

.width50{
  width: 50%;
}

.margin-right-1{
  margin-right: 1rem;
}

.full-width{
  width:100%
}

.MuiAlert-root.MuiAlert-icon{
  color:rgb(255, 152, 0)

}

.flex-container {
  width: 100%;
  height: 500px;
}

canvas {
  max-height: 500px !important 
}

.MuiTooltip-tooltipPlacementRight{
  background-color: white !important;
  border: 2px solid rgb(8, 63, 101) !important;
  color: rgb(8, 63, 101) !important
}


.MuiOutlinedInput-notchedOutline{
  border-color:#00bfa5 !important
}

.MuiCheckbox-root.Mui-checked{
  color: #00bfa5 !important
}

.MuiFormLabel-root.MuiInputLabel-root.Mui-focused{
  color: #0E1423 !important;
}

label{
  display: flex!important;
  align-items: center !important;
}

.rct-checkbox{
  display:flex !important;
  align-items: center !important;
  padding: 0 !important
}

.rct-title{
  padding: 0 !important;
}

.react-checkbox-tree ol{
  width: 500px;
}

.customized-scrollbar {
  overflow: auto;
}                  

.customized-scrollbar::-webkit-scrollbar {
  background-color: #00000000;
  width: 10px;
}

.customized-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 8px;
  border: 2px solid #ffffff;
}

.customized-scrollbar::-webkit-scrollbar-track-piece {
  margin-top: 2px;
  margin-bottom: 2px;
}