.circle {
    min-width: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
}

.vert-tab {
    height: 80%;
    width: 6px;
    min-height: 32px;
    min-width: 4px;
    position: absolute;
}

.summary-metric {
    width: .5rem;
    height: 1.2rem;
    min-width: .5rem;
    min-height: 1px;
}

.indicator {
    height: 37pt;
    width: 7px;
    margin-top: 5px;
}

.license-tab {
    min-width: 10px;
    min-height: 10px;
    max-width: 10px;
    max-height: 10px;
}

.point{
    height:6px;
    width:6px;
}

.initiative{

    height:11px;
    width:11px;
}

.table{
    width: 20px;
    height: 20px;
    border-radius: 250px
}

.l2-metric{
    width: .4rem;
    height: 1.6rem;
    min-width: .4rem;
    min-height: 1.6px;
}